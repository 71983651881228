import {
    Box,
    Flex,
    Text,
    Icon,
    useTheme,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    ModalFooter,
    Button
} from "@chakra-ui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { NotificationProps } from "models/notifications/NotificationProps";
import { useDeleteNotificationMutation } from "api/notifications/useDeleteNotificationMutation";
import { hexToRgba } from "utils/helpers";
import { truncateString } from "../ui/helpers";
import { Dispatch, SetStateAction } from "react";

interface NotificationItemProps {
    notification: NotificationProps;
    mainColor: string;
    icon: React.ComponentType;
    formatDate: (date: Date) => string;
    setNotificationItemOpen: Dispatch<SetStateAction<boolean>>;
    handleCloseNotificationsMenu: () => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({
    notification,
    mainColor,
    icon,
    formatDate,
    setNotificationItemOpen,
    handleCloseNotificationsMenu
}) => {
    // Theme
    const { colors } = useTheme();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    const isLight = colorMode === "light";
    const itemBg = isLight
        ? "background"
        : hexToRgba(colors.secondary[100], 0.15);

    const { mutate: deleteNotificationMutation } = useDeleteNotificationMutation();

    const fullTitle = notification.title || notification.type.toUpperCase();
    const truncatedTitle = truncateString(fullTitle, 18);

    const handleNotificationOpen = () => {
        onOpen()
        setNotificationItemOpen(true)
    }

    const handleNotificationRemove = (e: React.MouseEvent) => {
        e.stopPropagation();
        deleteNotificationMutation(notification.id);
    };

    const handleAcceptInvitation = (e: React.MouseEvent) => {
        if (notification.redirect_link) {
            onClose();
            setNotificationItemOpen(false)
            handleCloseNotificationsMenu()
            window.location.href = notification.redirect_link;
        }
    };

    const handleCloseModal = () => {
        onClose();
        setNotificationItemOpen(false)
    };

    const isTeamInvitation = notification.type === 'team_invitation';

    return (
        <>
            <Box
                px={1}
                py={1.5}
                bg={notification?.is_read ? itemBg : "lightBackground"}
                borderRadius="md"
                borderLeft="4px solid"
                borderColor={mainColor}
                boxShadow="0 1px 3px rgba(0,0,0,0.1)"
                transition="all 0.2s ease"
                cursor="pointer"
                onClick={handleNotificationOpen}
                _hover={{
                    transform: "translateY(-1px)",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
                    bg: notification?.is_read
                        ? hexToRgba(colors.secondary[100], 0.15)
                        : "lightBackground",
                }}
            >
                <Flex justify="space-between" align="center" mb={2}>
                    <Flex align="center" gap={1} minW={0} flex={1}>
                        <Icon as={icon} color={mainColor} boxSize={4} flexShrink={0} />
                        <Tooltip
                            label={fullTitle}
                            hasArrow
                            placement="top"
                            openDelay={300}
                        >
                            <Text
                                fontSize="12px"
                                color={mainColor}
                                fontWeight={!notification?.is_read ? "semibold" : "normal"}
                                letterSpacing="tight"
                                fontStyle="italic"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                minW={0}
                                flex={1}
                            >
                                {truncatedTitle}
                            </Text>
                        </Tooltip>
                    </Flex>
                    <Flex align="center" gap={1} ml={2} flexShrink={0}>
                        <Text
                            fontSize="10px"
                            fontWeight={!notification?.is_read ? "semibold" : "normal"}
                            color={!notification?.is_read ? "gray.600" : "gray.500"}
                            fontStyle="italic"
                            minW="fit-content"
                        >
                            {formatDate(new Date(notification.created_at))}
                        </Text>
                        <Icon
                            as={IoCloseCircleOutline}
                            boxSize={4}
                            color="gray.400"
                            cursor="pointer"
                            title="remove notification"
                            _hover={{ color: "red.400" }}
                            transition="all 0.3s ease-in-out"
                            onClick={(e) => handleNotificationRemove(e)}
                            flexShrink={0}
                        />
                    </Flex>
                </Flex>
                <Text
                    fontSize="10"
                    fontWeight={!notification?.is_read ? "semibold" : "normal"}
                    color={!notification?.is_read ? "gray.700" : "gray.500"}
                    wordBreak="break-all"
                    pl={2}
                >
                    {notification.message}
                </Text>
            </Box>

            <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size="md">
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        <Flex align="center" gap={2}>
                            <Icon as={icon} color={mainColor} boxSize={5} />
                            <Text color={mainColor}>{fullTitle}</Text>
                        </Flex>
                        <Text fontSize="xs" color="gray.500" mt={1}>
                            {formatDate(new Date(notification.created_at))}
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton onClick={() => setNotificationItemOpen(false)} />
                    <ModalBody pb={6}>
                        <Text>{notification.message}</Text>
                    </ModalBody>
                    {isTeamInvitation && notification.redirect_link && (
                        <ModalFooter>
                            <Button
                                colorScheme="blue"
                                onClick={handleAcceptInvitation}
                                mr={3}
                            >
                                Accept Invitation
                            </Button>
                            <Button variant="ghost" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default NotificationItem;
