import { createContext, useContext, useState, ReactNode } from "react";
import { useQueryClient } from "@tanstack/react-query";

interface RefreshContextType {
  isRefreshing: boolean;
  refreshTableData: () => Promise<void>;
}

const RefreshContext = createContext<RefreshContextType | null>(null);

export const useRefreshContext = (): RefreshContextType => {
  const context = useContext(RefreshContext);
  if (!context) {
    throw new Error("useRefreshContext must be used within a RefreshProvider");
  }
  return context;
};

interface RefreshProviderProps {
  children: ReactNode;
}

export function RefreshProvider({ children }: RefreshProviderProps) {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const refreshTableData = async () => {
    setIsRefreshing(true);
    await queryClient.invalidateQueries({
      queryKey: ["team"],
      refetchType: "all",
    });
    setIsRefreshing(false);
  };

  return (
    <RefreshContext.Provider value={{ isRefreshing, refreshTableData }}>
      {children}
    </RefreshContext.Provider>
  );
}
