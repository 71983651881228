import { Th } from "@chakra-ui/react";
import { headers } from "./helpers";

interface HeaderProps {
  text: string;
  index: number;
}

export default function Header({ text, index }: HeaderProps) {
  return (
    <Th
      key={"table-header" + index}
      py={2}
      px={2}
      textAlign={"left"}
      position="sticky"
      top={0}
      zIndex={1}
      bg="gray.100"
      borderLeftRadius={index === 0 ? "md" : "none"}
      borderRightRadius={index === headers?.length - 1 ? "md" : "none"}
      borderRight={index !== headers?.length - 1 ? "1px solid" : "none"}
      borderRightColor={"gray.200"}
    >
      {text}
    </Th>
  );
}
