import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { RiUserSettingsLine } from "react-icons/ri";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onEditOption: () => void;
  saving: boolean;
  selectedOption: string;
  onChangeOption: (s: "restore" | "delete") => void;
}

function ManageInactiveMemberModal({
  isOpen,
  onClose,
  onEditOption,
  saving,
  selectedOption,
  onChangeOption,
}: Props) {
  // Theme
  const { colors } = useTheme();
  const { lGradient } = GetGradients();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgGradient = isLight
    ? lGradient
    : `linear(to-b, ${colors.dark[400]}, ${colors.dark[400]})`;

  const handleRadioChange = (nextValue: "restore" | "delete") => {
    onChangeOption(nextValue);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        p={4}
        gap={4}
        alignSelf={"center"}
        alignItems={"center"}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        color={"gray.600"}
        bgGradient={bgGradient}
        borderRadius={"6px"}
      >
        <ModalHeader
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={2}
          p={0}
          w={"100%"}
        >
          <Icon
            as={RiUserSettingsLine}
            boxSize={10}
            borderRadius={"50%"}
            p={2}
            color={"secondary.500"}
            bg={hexToRgba(colors.secondary[500], 0.12)}
          />
          <Text color={"gray.700"} fontSize={"16px"} fontWeight={"500"}>
            Manage member
          </Text>
        </ModalHeader>

        <ModalBody p={0} w={"100%"} my={4} textAlign={"center"}>
          <RadioGroup
            defaultValue="restore"
            name="removal"
            onChange={handleRadioChange}
            mt={2}
            textAlign={"left"}
            w={"100%"}
          >
            <Flex
              gap={0}
              w={"100%"}
              direction={"column"}
              boxShadow={"sm"}
              borderRadius={"10px"}
            >
              {/* restore option */}
              <Flex
                bg={"background"}
                p={4}
                gap={3}
                w={"100%"}
                borderRadius={"10px"}
                borderEndStartRadius={0}
                borderEndEndRadius={0}
                borderWidth={1}
                borderColor={"gray.200"}
                borderTopWidth={0}
              >
                <Radio
                  mt={"5px"}
                  value="restore"
                  h={"fit-content"}
                  size={"sm"}
                  _checked={{
                    bg: hexToRgba(colors.highlight.primary, 0.15),
                    border: "0.22rem solid #00C1B4",
                  }}
                />
                <Flex direction={"column"} gap={1} color={"gray.600"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    color={
                      selectedOption === "restore"
                        ? "highlight.primary"
                        : "gray.600"
                    }
                  >
                    Restore member
                  </Text>

                  <Text fontSize={"13px"}>
                    Restore this member's access to the team and resources,
                    returning their permissions. This action can be reversed.
                  </Text>
                </Flex>
              </Flex>

              {/* delete option */}
              <Flex
                bg={"background"}
                p={4}
                gap={3}
                w={"100%"}
                borderRadius={"10px"}
                borderStartStartRadius={0}
                borderStartEndRadius={0}
                borderWidth={1}
                borderColor={"gray.200"}
                borderTopWidth={0}
              >
                <Radio
                  mt={"5px"}
                  value="delete"
                  h={"fit-content"}
                  size={"sm"}
                  _checked={{
                    bg: hexToRgba(colors.red[500], 0.15),
                    border: `0.22rem solid ${colors.red[500]}`,
                  }}
                />
                <Flex direction={"column"} gap={1} color={"gray.600"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    color={selectedOption === "delete" ? "red.500" : "gray.600"}
                  >
                    Delete member
                  </Text>

                  <Text fontSize={"13px"}>
                    Remove this member{" "}
                    <Text as={"span"} fontWeight={"500"}>
                      permanently
                    </Text>{" "}
                    from the team, revoking their access to all team resources.
                    This action is irreversible!
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </RadioGroup>
        </ModalBody>

        <ModalFooter w={"100%"} gap={2} p={0}>
          <Button
            bg={"transparent"}
            borderWidth={1}
            borderColor={"gray.300"}
            color={"gray.600"}
            _hover={{ opacity: 0.8, bg: "transparent" }}
            _focus={{ opacity: 1, bg: "transparent" }}
            borderRadius={"30px"}
            w={"100%"}
            fontSize={"14px"}
            letterSpacing={"0.04rem"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color={"whiteAlpha.900"}
            bg={isLight ? "secondary.500" : "secondary.200"}
            borderWidth={1}
            borderColor={isLight ? "secondary.500" : "secondary.200"}
            _hover={{
              opacity: 0.8,
              bg: isLight ? "secondary.500" : "secondary.200",
            }}
            _focus={{
              opacity: 1,
              bg: isLight ? "secondary.500" : "secondary.200",
            }}
            borderRadius={"30px"}
            w={"100%"}
            fontSize={"14px"}
            letterSpacing={"0.04rem"}
            loadingText={"Saving..."}
            isLoading={saving}
            _loading={{ pointerEvents: "none" }}
            onClick={onEditOption}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ManageInactiveMemberModal;
