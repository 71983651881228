import { format, isToday, isYesterday, isSameYear } from "date-fns";
import { IoIosCheckmarkCircleOutline, IoMdInformationCircleOutline } from "react-icons/io";
import { CiWarning } from "react-icons/ci";
import { VscError } from "react-icons/vsc";
import { MdPersonAdd } from "react-icons/md";

export const formatDate = (date: Date) => {
    const now = new Date();
    if (isToday(date)) {
        return `Today, ${format(date, "HH:mm")}`;
    } else if (isYesterday(date)) {
        return `Yesterday, ${format(date, "HH:mm")}`;
    } else if (isSameYear(date, now)) {
        return format(date, "MMMM dd, HH:mm");
    } else {
        return format(date, "MMM dd, yyyy HH:mm");
    }
};

export const getNotificationProps = (type: 'info' | 'success' | 'error' | 'warning' | 'team_invitation') => {
    switch (type) {
        case "info":
            return {
                lightColor: "blue.300",
                darkColor: "blue.500",
                status_color: "info",
                icon: IoMdInformationCircleOutline,
            };
        case "success":
            return {
                lightColor: "green.400",
                darkColor: "green.500",
                status_color: "success",
                icon: IoIosCheckmarkCircleOutline,
            };
        case "error":
            return {
                lightColor: "red.300",
                darkColor: "red.500",
                status_color: "error",
                icon: VscError,
            };
        case "warning":
            return {
                lightColor: "orange.300",
                darkColor: "orange.500",
                status_color: "warning",
                icon: CiWarning,
            };
        case "team_invitation":
            return {
                lightColor: "green.400",
                darkColor: "green.500",
                status_color: "success",
                icon: MdPersonAdd,
            };
        default:
            return {
                lightColor: "blue.300",
                darkColor: "blue.500",
                status_color: "info",
                icon: IoMdInformationCircleOutline,
            };
    }
};