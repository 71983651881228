import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { useNotificationsAPI } from './useNotificationsAPI';
import { errorHandler } from 'utils/helpers';
import { NotificationsResponse } from 'models/notifications/NotificationProps';

export const useDeleteNotificationMutation = () => {
    const { removeNotification } = useNotificationsAPI();
    const toast = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            queryClient.setQueryData<InfiniteData<NotificationsResponse>>(
                ["notifications"],
                (oldData) => {
                    if (!oldData) return oldData;
                    return {
                        ...oldData,
                        pages: oldData.pages.map(page => ({
                            ...page,
                            notifications: page.notifications.filter(n => {
                                return n.id !== id
                            })
                        }))
                    };
                }
            );

            await removeNotification(id);
            return id
        },
        onSuccess: async (id: string) => {
            // .. 
        },
        onError: (error: any) => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] });
            toast({
                description: errorHandler(error).message,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            });
        },
    });
};