import { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";

import { useTeamsAPI } from "api/useTeamsAPI";
import { useUsersAPI } from "api/useUsersAPI";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import ManageInactiveMemberModal from "./ManageInactiveMemberModal";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import ManageMemberModal from "./ManageMemberModal";
import PendingInvites from "../PendingInvites";
import Header from "./Header";
import Row from "./Row";

import { MemberProps, TeamProps } from "models/posts/PostProps";
import { errorHandler } from "utils/helpers";
import { headers } from "./helpers";

interface DataProps {
  members: {
    user: MemberProps;
    permission: string;
    is_revoked?: boolean;
  }[];
  isOwner: boolean;
  teamData: TeamProps;
}

export interface RowProps {
  user: MemberProps;
  permission: string;
  index: number;
  teamData: TeamProps;
  isOwner: boolean;
  isRevoked: boolean;
  onManageMember: (id: string, permission: string) => void;
}

const tableStyle = {
  height: "94%",
  width: "100%",
  padding: "0",
};

const TableData = ({ members, isOwner, teamData }: DataProps) => {
  // Hooks
  const toast = useToast();
  const queryClient = useQueryClient();
  const { user } = useSelector(selectCurrentAuthData);

  // APIs
  const { removeTeamMember, editTeam } = useTeamsAPI();
  const { saveUserActivity } = useUsersAPI();

  // States
  const [showManageMemberModal, setShowManageMemberModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [permission, setPermission] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<
    "member" | "collaborator"
  >(permission as "member" | "collaborator");
  const [selectedRemoval, setSelectedRemoval] = useState<
    "deactivate" | "delete"
  >("deactivate");
  const [selectedOption, setSelectedOption] = useState<"restore" | "delete">(
    "restore"
  );

  const tableHeaders = isOwner ? headers : headers?.slice(0, -1);

  const selectedUser = useMemo(
    () => members?.find((m) => m?.user?.id === selectedMember),
    [selectedMember, members]
  );

  function handleChangePermission(s: "member" | "collaborator") {
    setSelectedPermission(s);
  }

  function handleChangeRemoval(s: "deactivate" | "delete") {
    setSelectedRemoval(s);
  }

  function handleChangeOption(s: "restore" | "delete") {
    setSelectedOption(s);
  }

  const editPermissionMutation = useMutation({
    mutationFn: (memberId: string) =>
      editTeam({
        operation: "member",
        memberId: memberId,
        permission: selectedPermission,
      }),
    onSuccess: () => {
      toast({
        position: "top-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Permission switched to <Text as={"span"}>{selectedPermission}</Text>
          </Box>
        ),
      });
      setLoading(false);
      setShowManageMemberModal(false);
      setPermission(selectedPermission);
      queryClient.invalidateQueries({ queryKey: ["team"] });
    },
    onError: (error: any) => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
      setLoading(false);
    },
  });

  const deactivateMemberMutation = useMutation({
    mutationFn: (memberId: string) =>
      editTeam({
        operation: "member",
        memberId: memberId,
        permission: selectedPermission,
        is_revoked: !selectedUser?.is_revoked,
      }),
    onSuccess: () => {
      toast({
        position: "top-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            {selectedUser?.is_revoked ? "Restored" : "Revoked"} member access to
            this team
          </Box>
        ),
      });
      setLoading(false);
      setShowManageMemberModal(false);
      setPermission(selectedPermission);
      queryClient.invalidateQueries({ queryKey: ["team"] });
    },
    onError: (error: any) => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
      setLoading(false);
    },
  });

  const removeMemberMutation = useMutation({
    mutationFn: (memberId: string) => removeTeamMember("member", memberId),
    onSuccess: async () => {
      // Save User activity Logs
      const activity = {
        user_id: user.id,
        activity: `Removed member: ${selectedUser?.user?.email} from team`, // TODO: to double check with Gideon
      };
      await saveUserActivity(activity);

      toast({
        position: "top-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Removed member from this team
          </Box>
        ),
      });
      setLoading(false);
      setShowManageMemberModal(false);
      queryClient.invalidateQueries({ queryKey: ["team"] });
    },
    onError: (error: any) => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
      setLoading(false);
    },
  });

  // Handlers
  function onOpenManageMemberModal(memberId: string, permission: string) {
    // TODO: to double check with Gideon
    // make sure only owner can manage membership
    // if (!isOwner || memberId === teamData.owner) return;

    if (permission === "member" || permission === "collaborator") {
      setPermission(permission);
      setSelectedPermission(permission);
      setSelectedMember(memberId);
      setShowManageMemberModal(true);
    }
  }

  // edit active member permission: member or collaborator
  function handleEditPermission() {
    // no action is needed, permission not changed by admin
    if (selectedPermission === permission) return;

    setLoading(true);
    editPermissionMutation.mutate(selectedMember);
  }

  // edit active member removal: deactivate or delete
  function handleEditRemoval() {
    if (selectedRemoval === "deactivate") {
      setLoading(true);
      deactivateMemberMutation.mutate(selectedMember);
    } else if (selectedRemoval === "delete") {
      setLoading(true);
      removeMemberMutation.mutate(selectedMember);
    } else {
      console.error(`${selectedRemoval} is not a valid option!`);
    }
  }

  // edit inactive member
  function handleEditOption() {
    if (selectedOption === "restore") {
      setLoading(true);
      deactivateMemberMutation.mutate(selectedMember);
    } else if (selectedOption === "delete") {
      setLoading(true);
      removeMemberMutation.mutate(selectedMember);
    } else {
      console.error(`${selectedOption} is not a valid option!`);
    }
  }

  const showActiveMemberModal =
    !!showManageMemberModal && !selectedUser?.is_revoked;
  const showInactiveMemberModal =
    !!showManageMemberModal && !!selectedUser?.is_revoked;

  return (
    <TableContainer height="88%" style={{ position: "relative" }}>
      <CustomScrollBar style={tableStyle}>
        <Table variant="simple" size="sm">
          {members?.length > 0 ? (
            <>
              <Thead>
                <Tr>
                  {tableHeaders?.map((text, index) => (
                    <Header
                      key={"table-header" + index}
                      text={text}
                      index={index}
                    />
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {members?.map((row: any, index: number) => {
                  const isRevoked = !!row?.is_revoked;

                  return (
                    <Row
                      index={index}
                      user={row?.user}
                      isRevoked={isRevoked}
                      permission={row?.permission}
                      key={"table-row" + index}
                      isOwner={isOwner}
                      teamData={teamData}
                      onManageMember={onOpenManageMemberModal}
                    />
                  );
                })}
              </Tbody>
            </>
          ) : (
            <Text color={"gray.500"} marginTop={20} textAlign={"center"}>
              You do not have any team member yet. Start by inviting a team
              member to collaborate.
            </Text>
          )}
        </Table>
      </CustomScrollBar>

      <PendingInvites isOwner={isOwner} />

      {/* manage active member */}
      {!!showActiveMemberModal && (
        <ManageMemberModal
          selectedUser={selectedUser}
          selectedPermission={selectedPermission}
          selectedRemoval={selectedRemoval}
          onChangePermission={handleChangePermission}
          onChangeRemoval={handleChangeRemoval}
          permission={permission}
          changingPermission={loading}
          editingRemoval={loading}
          onEditPermision={handleEditPermission}
          onEditRemoval={handleEditRemoval}
          isOpen={showManageMemberModal}
          onClose={() => setShowManageMemberModal(false)}
        />
      )}

      {/* manage inactive member */}
      {!!showInactiveMemberModal && (
        <ManageInactiveMemberModal
          selectedOption={selectedOption}
          onChangeOption={handleChangeOption}
          onEditOption={handleEditOption}
          isOpen={showManageMemberModal}
          saving={loading}
          onClose={() => setShowManageMemberModal(false)}
        />
      )}
    </TableContainer>
  );
};

export default TableData;
