import { useState } from "react";
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useTheme,
} from "@chakra-ui/react";

import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";

import {
  RiUserSettingsLine,
  RiUserForbidLine,
  RiUserFollowLine,
} from "react-icons/ri";
import { MemberProps } from "models/posts/PostProps";

interface ManageMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  permission: string;
  selectedUser?: {
    user: MemberProps;
    permission: string;
    is_revoked?: boolean;
  };
  changingPermission: boolean;
  editingRemoval: boolean;
  selectedPermission: "member" | "collaborator";
  selectedRemoval: "deactivate" | "delete";
  onEditPermision: () => void;
  onEditRemoval: () => void;
  onChangePermission: (s: "member" | "collaborator") => void;
  onChangeRemoval: (s: "deactivate" | "delete") => void;
}

interface PermissionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  permission: string;
  selectedOption: string;
  changingPermission: boolean;
  onChangeOption: (s: "member" | "collaborator") => void;
}

interface RemovalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedOption: string;
  editingRemoval: boolean;
  onChangeOption: (s: "deactivate" | "delete") => void;
}

export default function ManageMemberModal({
  isOpen,
  onClose,
  permission,
  selectedUser,
  onEditPermision,
  onEditRemoval,
  selectedPermission,
  selectedRemoval,
  onChangePermission,
  onChangeRemoval,
  changingPermission,
  editingRemoval,
}: ManageMemberModalProps) {
  // States
  const [showPermission, setShowPermission] = useState(false);
  const [showRemoval, setShowRemoval] = useState(false);

  // Theme
  const { colors } = useTheme();
  const { lGradient } = GetGradients();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgGradient = isLight
    ? lGradient
    : `linear(to-b, ${colors.dark[400]}, ${colors.dark[400]})`;

  function handleSelectedOption(option: "permission" | "removal") {
    if (option === "permission") setShowPermission(true);
    else setShowRemoval(true);
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent
          p={4}
          gap={4}
          alignSelf={"center"}
          alignItems={"center"}
          w={"fit-content"}
          minW={"412px"}
          minH={"164px"}
          color={"gray.600"}
          bgGradient={bgGradient}
          borderRadius={"6px"}
        >
          <ModalHeader
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={2}
            p={0}
            w={"100%"}
          >
            <Icon
              as={RiUserSettingsLine}
              boxSize={10}
              borderRadius={"50%"}
              p={2}
              color={"secondary.500"}
              bg={hexToRgba(colors.secondary[500], 0.12)}
            />
            <Text color={"gray.700"} fontSize={"16px"} fontWeight={"500"}>
              Manage member
            </Text>
          </ModalHeader>

          <ModalBody p={0} w={"100%"} my={4} textAlign={"center"}>
            <Flex direction={"column"} gap={4}>
              <Flex direction={"column"} gap={2}>
                {/* Switch permission option */}
                {!selectedUser?.is_revoked && (
                  <Flex
                    bg={"background"}
                    cursor={"pointer"}
                    align={"center"}
                    gap={3}
                    borderRadius={"10px"}
                    borderWidth={1}
                    borderColor={"gray.200"}
                    p={3}
                    w={"100%"}
                    transition={"all 0.3s ease"}
                    _hover={{
                      bg: hexToRgba(colors.highlight.primary, 0.04),
                      borderColor: "highlight.primary",
                    }}
                    onClick={() => handleSelectedOption("permission")}
                  >
                    <Icon
                      as={RiUserFollowLine}
                      boxSize={10}
                      borderRadius={"10px"}
                      p={2}
                      color={"highlight.primary"}
                    />

                    <Flex
                      direction={"column"}
                      gap={1}
                      color={"gray.600"}
                      align={"flex-start"}
                    >
                      <Text
                        fontSize={"14px"}
                        fontWeight={"500"}
                        textAlign={"left"}
                      >
                        Switch member permission
                      </Text>

                      <Text fontSize={"13px"} textAlign={"left"}>
                        Revoke this member ability to access the team
                        temporarily, or remove it permanently.
                      </Text>
                    </Flex>
                  </Flex>
                )}

                {/* deactivate or delete option */}
                <Flex
                  bg={"background"}
                  cursor={"pointer"}
                  gap={3}
                  borderRadius={"10px"}
                  borderWidth={1}
                  borderColor={"gray.200"}
                  p={3}
                  w={"100%"}
                  transition={"all 0.3s ease"}
                  _hover={{
                    bg: hexToRgba(colors.red[500], 0.04),
                    borderColor: "red.500",
                  }}
                  onClick={() => handleSelectedOption("removal")}
                >
                  <Icon
                    as={RiUserForbidLine}
                    boxSize={10}
                    borderRadius={"10px"}
                    p={2}
                    color={"red.500"}
                  />

                  <Flex
                    direction={"column"}
                    gap={1}
                    color={"gray.600"}
                    align={"flex-start"}
                  >
                    <Text
                      fontSize={"14px"}
                      fontWeight={"500"}
                      textAlign={"left"}
                    >
                      Deactivate or delete member
                    </Text>

                    <Text fontSize={"13px"} textAlign={"left"}>
                      Revoke this member ability to access the team temporarily,
                      or remove it permanently.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {!!showRemoval && (
        <RemovalModal
          editingRemoval={editingRemoval}
          selectedOption={selectedRemoval}
          onChangeOption={onChangeRemoval}
          isOpen={showRemoval}
          onClose={() => {
            setShowRemoval(false);
            onClose();
          }}
          onConfirm={onEditRemoval}
        />
      )}

      {!!showPermission && (
        <PermissionModal
          changingPermission={changingPermission}
          selectedOption={selectedPermission}
          onChangeOption={onChangePermission}
          permission={permission}
          isOpen={showPermission}
          onClose={() => {
            setShowPermission(false);
            onClose();
          }}
          onConfirm={onEditPermision}
        />
      )}
    </>
  );
}

function PermissionModal({
  isOpen,
  onClose,
  onConfirm,
  permission,
  selectedOption,
  changingPermission,
  onChangeOption,
}: PermissionModalProps) {
  // Theme
  const { colors } = useTheme();
  const { lGradient } = GetGradients();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgGradient = isLight
    ? lGradient
    : `linear(to-b, ${colors.dark[400]}, ${colors.dark[400]})`;

  const handleRadioChange = (nextValue: "member" | "collaborator") => {
    onChangeOption(nextValue);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        p={4}
        gap={4}
        alignSelf={"center"}
        alignItems={"center"}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        color={"gray.600"}
        bgGradient={bgGradient}
        borderRadius={"6px"}
      >
        <ModalHeader
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={2}
          p={0}
          w={"100%"}
        >
          <Icon
            as={RiUserFollowLine}
            boxSize={10}
            borderRadius={"50%"}
            p={2}
            color={"highlight.primary"}
            bg={hexToRgba(colors.highlight.primary, 0.12)}
          />
          <Text color={"gray.700"} fontSize={"16px"} fontWeight={"500"}>
            Switch member permission
          </Text>
        </ModalHeader>

        <ModalBody p={0} w={"100%"} my={4} textAlign={"center"}>
          <RadioGroup
            defaultValue={permission}
            name="removal"
            onChange={handleRadioChange}
            mt={2}
            textAlign={"left"}
            w={"100%"}
          >
            <Flex
              gap={0}
              w={"100%"}
              direction={"column"}
              boxShadow={"sm"}
              borderRadius={"10px"}
            >
              {/* member option */}
              <Flex
                bg={"background"}
                p={4}
                gap={3}
                w={"100%"}
                borderRadius={"10px"}
                borderEndStartRadius={0}
                borderEndEndRadius={0}
                borderWidth={1}
                borderColor={"gray.200"}
                borderTopWidth={0}
              >
                <Radio
                  mt={"0.35rem"}
                  value="member"
                  h={"fit-content"}
                  size={"sm"}
                  _checked={{
                    bg: hexToRgba(colors.highlight.primary, 0.15),
                    border: "0.22rem solid #00C1B4",
                  }}
                />
                <Flex direction={"column"} gap={1} color={"gray.600"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    color={
                      selectedOption === "member"
                        ? "highlight.primary"
                        : "gray.600"
                    }
                  >
                    Set as member
                  </Text>

                  <Text fontSize={"13px"}>
                    Restrict this member ability to access the team{" "}
                    <Text as={"span"} fontWeight={"500"}>
                      temporarily
                    </Text>
                    . This action can be reversed to restore access later.
                  </Text>
                </Flex>
              </Flex>

              {/* collaborator option */}
              <Flex
                bg={"background"}
                p={4}
                gap={3}
                w={"100%"}
                borderRadius={"10px"}
                borderStartStartRadius={0}
                borderStartEndRadius={0}
                borderWidth={1}
                borderColor={"gray.200"}
                borderTopWidth={0}
              >
                <Radio
                  mt={"0.35rem"}
                  value="collaborator"
                  h={"fit-content"}
                  size={"sm"}
                  _checked={{
                    bg: hexToRgba(colors.highlight.primary, 0.15),
                    border: "0.22rem solid #00C1B4",
                  }}
                />
                <Flex direction={"column"} gap={1} color={"gray.600"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    color={
                      selectedOption === "collaborator"
                        ? "highlight.primary"
                        : "gray.600"
                    }
                  >
                    Set as collaborator
                  </Text>

                  <Text fontSize={"13px"}>
                    Remove this member{" "}
                    <Text as={"span"} fontWeight={"500"}>
                      permanently
                    </Text>{" "}
                    from the team, revoking their access to all team resources.
                    This action is irreversible!
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </RadioGroup>
        </ModalBody>

        <ModalFooter w={"100%"} gap={2} p={0}>
          <Button
            bg={"transparent"}
            borderWidth={1}
            borderColor={"gray.300"}
            color={"gray.600"}
            _hover={{ opacity: 0.8, bg: "transparent" }}
            _focus={{ opacity: 1, bg: "transparent" }}
            borderRadius={"30px"}
            w={"100%"}
            fontSize={"14px"}
            letterSpacing={"0.04rem"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color={"whiteAlpha.900"}
            bg={"highlight.primary"}
            borderWidth={1}
            borderColor={"highlight.primary"}
            _hover={{ opacity: 0.8, bg: "highlight.primary" }}
            _focus={{ opacity: 1, bg: "highlight.primary" }}
            borderRadius={"30px"}
            w={"100%"}
            fontSize={"14px"}
            letterSpacing={"0.04rem"}
            loadingText={"Saving..."}
            isLoading={changingPermission}
            _loading={{ pointerEvents: "none" }}
            onClick={onConfirm}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function RemovalModal({
  isOpen,
  onClose,
  onConfirm,
  editingRemoval,
  onChangeOption,
  selectedOption,
}: RemovalModalProps) {
  // Theme
  const { colors } = useTheme();
  const { lGradient } = GetGradients();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgGradient = isLight
    ? lGradient
    : `linear(to-b, ${colors.dark[400]}, ${colors.dark[400]})`;

  const handleRadioChange = (nextValue: "deactivate" | "delete") => {
    onChangeOption(nextValue);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        p={4}
        gap={4}
        alignSelf={"center"}
        alignItems={"center"}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        color={"gray.600"}
        bgGradient={bgGradient}
        borderRadius={"6px"}
      >
        <ModalHeader
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={2}
          p={0}
          w={"100%"}
        >
          <Icon
            as={RiUserForbidLine}
            boxSize={10}
            borderRadius={"50%"}
            p={2}
            color={"red.500"}
            bg={hexToRgba(colors.red[500], 0.12)}
          />
          <Text color={"gray.700"} fontSize={"16px"} fontWeight={"500"}>
            Deactivate or delete this team member
          </Text>
        </ModalHeader>

        <ModalBody p={0} w={"100%"} my={4} textAlign={"center"}>
          <RadioGroup
            defaultValue="deactivate"
            name="removal"
            onChange={handleRadioChange}
            mt={2}
            textAlign={"left"}
            w={"100%"}
          >
            <Flex
              gap={0}
              w={"100%"}
              direction={"column"}
              boxShadow={"sm"}
              borderRadius={"10px"}
            >
              {/* deactivate option */}
              <Flex
                bg={"background"}
                p={4}
                gap={3}
                w={"100%"}
                borderRadius={"10px"}
                borderEndStartRadius={0}
                borderEndEndRadius={0}
                borderWidth={1}
                borderColor={"gray.200"}
                borderTopWidth={0}
              >
                <Radio
                  mt={"5px"}
                  value="deactivate"
                  h={"fit-content"}
                  size={"sm"}
                  _checked={{
                    bg: hexToRgba(colors.red[500], 0.15),
                    border: `0.22rem solid ${colors.red[500]}`,
                  }}
                />
                <Flex direction={"column"} gap={1} color={"gray.600"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    color={
                      selectedOption === "deactivate" ? "red.500" : "gray.600"
                    }
                  >
                    Deactivate member
                  </Text>

                  <Text fontSize={"13px"}>
                    Restrict this member ability to access the team{" "}
                    <Text as={"span"} fontWeight={"500"}>
                      temporarily
                    </Text>
                    . This action can be reversed to restore access later.
                  </Text>
                </Flex>
              </Flex>

              {/* delete option */}
              <Flex
                bg={"background"}
                p={4}
                gap={3}
                w={"100%"}
                borderRadius={"10px"}
                borderStartStartRadius={0}
                borderStartEndRadius={0}
                borderWidth={1}
                borderColor={"gray.200"}
                borderTopWidth={0}
              >
                <Radio
                  mt={"5px"}
                  value="delete"
                  h={"fit-content"}
                  size={"sm"}
                  _checked={{
                    bg: hexToRgba(colors.red[500], 0.15),
                    border: `0.22rem solid ${colors.red[500]}`,
                  }}
                />
                <Flex direction={"column"} gap={1} color={"gray.600"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    color={selectedOption === "delete" ? "red.500" : "gray.600"}
                  >
                    Delete member
                  </Text>

                  <Text fontSize={"13px"}>
                    Remove this member{" "}
                    <Text as={"span"} fontWeight={"500"}>
                      permanently
                    </Text>{" "}
                    from the team, revoking their access to all team resources.
                    This action is irreversible!
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </RadioGroup>
        </ModalBody>

        <ModalFooter w={"100%"} gap={2} p={0}>
          <Button
            bg={"transparent"}
            borderWidth={1}
            borderColor={"gray.300"}
            color={"gray.600"}
            _hover={{ opacity: 0.8, bg: "transparent" }}
            _focus={{ opacity: 1, bg: "transparent" }}
            borderRadius={"30px"}
            w={"100%"}
            fontSize={"14px"}
            letterSpacing={"0.04rem"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color={"whiteAlpha.900"}
            bg={isLight ? "red.500" : "red.200"}
            borderWidth={1}
            borderColor={isLight ? "red.500" : "red.200"}
            _hover={{ opacity: 0.8, bg: isLight ? "red.500" : "red.200" }}
            _focus={{ opacity: 1, bg: isLight ? "red.500" : "red.200" }}
            borderRadius={"30px"}
            w={"100%"}
            fontSize={"14px"}
            letterSpacing={"0.04rem"}
            loadingText={"Saving..."}
            isLoading={editingRemoval}
            _loading={{ pointerEvents: "none" }}
            onClick={onConfirm}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
