import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { SelectedUsersProps } from "models/posts/PostProps";

interface createTeamPayload {
  name: string;
}

interface editTeamPayload {
  operation: string;
  memberId: string;
  permission: string;
  is_revoked?: boolean;
}

interface InviteMemberPayload {
  email: string;
  permission: string;
}

interface AcceptInvitePayload {
  email: string;
  invite_token: string;
}

export const useTeamsAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const getTeam = async () => {
    const response = await axiosPrivate.get(`/api/teams`);
    return response.data;
  };

  const createTeam = async (payload: createTeamPayload) => {
    const response = await axiosPrivate.post(`/api/manage_teams`, payload);
    return response.data;
  };

  const editTeam = async (payload: editTeamPayload) => {
    const response = await axiosPrivate.put(`/api/manage_teams`, payload);
    return response.data;
  };

  const removeTeamMember = async (operation: string, memberId: string = "") => {
    const response = await axiosPrivate.delete(
      `/api/manage_teams?operation=${operation}&memberId=${memberId}`
    );
    return response.data;
  };

  const inviteMember = async (payload: InviteMemberPayload) => {
    const response = await axiosPrivate.post(`/api/manage_invites`, payload);
    return response.data;
  };

  const acceptTeamInvite = async (payload: AcceptInvitePayload) => {
    const response = await axiosPrivate.put(`/api/accept_invites`, payload);
    return response.data;
  };

  const deleteTeamInvite = async (email: string) => {
    const response = await axiosPrivate.delete(
      `/api/manage_invites?email=${email}`
    );
    return response.data;
  };

  const fetchTeamMessages = async (
    teamId: string | undefined,
    pageNumber: number
  ) => {
    const response = await axiosPrivate.get(
      `/api/team_messages?teamId=${teamId}&pageNumber=${pageNumber}`
    );
    return response.data;
  };

  const fetchTeamMembersActivity = async (teamId: string) => {
    const response = await axiosPrivate.get(
      `/api/team_members_activity?teamId=${teamId}`
    );
    return response.data;
  };

  const updateSavedElementsAccessList = async (
    sharedList: SelectedUsersProps[],
    folderId: string | undefined
  ) => {
    const response = await axiosPrivate.put(`/api/saved_elements_access_list`, {
      sharedList: sharedList,
      folderId: folderId,
    });
    return response.data;
  };

  const fetchSavedElementsAccessList = async (folderId: string | undefined) => {
    const response = await axiosPrivate.get(`/api/saved_elements_access_list`, {
      params: {
        folderId,
      },
    });
    return response.data;
  };

  return {
    getTeam,
    createTeam,
    editTeam,
    removeTeamMember,
    inviteMember,
    acceptTeamInvite,
    deleteTeamInvite,
    fetchTeamMessages,
    fetchTeamMembersActivity,
    updateSavedElementsAccessList,
    fetchSavedElementsAccessList,
  };
};
