import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import {
  incrementUnreadMessages,
  setUnreadMessages,
} from "redux/features/posts/postsSlice";
import { useQuery } from "@tanstack/react-query";
import { number } from "echarts";

import { useToast } from "@chakra-ui/react";
import { useTeamsAPI } from "api/useTeamsAPI";
import {
  TeamMessageProps,
  TeamSocketContextProps,
} from "models/posts/PostProps";
import { TYPES } from "utils/premissions";

export const TeamSocketContext = createContext<TeamSocketContextProps>({
  teamSocket: { current: null },
  teamMessages: [],
  setTeamMessages: () => { },
  teamSocketConnected: false,
});

const usePostWS = () => {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const { getTeam } = useTeamsAPI();
  const reconnectInterval = useRef<any>(number);
  let teamSocket = useRef<WebSocket | null>(null);
  const { user } = useSelector(selectCurrentAuthData);

  // States
  const [teamMessages, setTeamMessages] = useState<TeamMessageProps[]>([]);
  const [teamSocketConnected, setTeamSocketConnected] = useState(false);

  const HOST_URL = window.location.hostname;

  const { data } = useQuery({
    queryKey: ["team"],
    queryFn: getTeam,
    retry: false,
    enabled: user?.user_type === TYPES.TEAMS,
  });

  // Handlers
  const handleConnection = () => {
    if (!data || !user) return;

    const teamId = data.id;

    const WS_URL =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? `ws://127.0.0.1:8000/ws/team_chat/${teamId}/${user?.id}/`
        : `wss://${HOST_URL}/ws/team_chat/${teamId}/${user?.id}/`;

    teamSocket.current = new WebSocket(WS_URL);

    teamSocket.current.onopen = () => {
      setTeamSocketConnected(true);
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };

    teamSocket.current.onmessage = (event: any) => {
      const eventData = JSON.parse(event.data);
      if (
        eventData.type === "chat_message" &&
        eventData.message.user_id !== user?.id
      ) {
        setTeamMessages((prevMessages) => [...prevMessages, eventData.message]);

        if (window.location.pathname.includes("/team/posts") === true) {
          teamSocket.current?.send(
            JSON.stringify({
              type: "read_one",
              message_id: eventData.message.id,
              user_id: user?.id,
            })
          );
        } else {
          dispatch(incrementUnreadMessages(1));
        }
      } else if (
        eventData.type === "read_receipt" &&
        eventData.data.user_id !== user?.id
      ) {
        setTeamMessages((prevMessages) => {
          for (let msg of prevMessages) {
            if (msg.id === eventData.data.message_id) {
              msg.read_by?.push(eventData.data.user_id);
            }
          }
          return prevMessages;
        });
      } else if (
        eventData.type === "un_read_messages" &&
        eventData.data.user_id === user?.id
      ) {
        dispatch(setUnreadMessages(eventData.data.count));
      }
    };

    teamSocket.current.onerror = () => {
      teamSocket.current?.close();
      if (window.location.pathname.includes("team/posts") && user) {
        toast({
          description:
            "Seems like there is an issue with connecting to the server!",
          status: "error",
          position: "top-right",
        });
      }
    };

    teamSocket.current.onclose = () => {
      setTeamSocketConnected(false);
      attemptReconnect();
    };
  };

  const attemptReconnect = () => {
    if (!reconnectInterval.current) {
      reconnectInterval.current = setInterval(() => {
        handleConnection();
      }, 5000); // Attempt to reconnect every 5 seconds
    }
  };

  useEffect(() => {
    if (teamSocketConnected === false) {
      handleConnection();
    }

    // cleanup: close socket connection when all requested data received
    return () => {
      !!teamSocket.current && teamSocket.current.close();
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { teamSocket, teamMessages, setTeamMessages, teamSocketConnected };
};

export default usePostWS;
