import { Flex, Icon, Text, useTheme } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";
import { TbLock } from "react-icons/tb";

function AccessDisclaimer() {
  // Theme
  const { colors } = useTheme();

  return (
    <Flex align={"center"} justify={"center"} h={"100%"} w={"100%"}>
      <Flex
        direction={"column"}
        gap={2}
        align={"center"}
        justify={"center"}
        color={"orange.500"}
        bg={hexToRgba(colors.orange[500], 0.1)}
        borderRadius={"10px"}
        borderColor={"orange.300"}
        borderWidth={1}
        borderStyle={"dashed"}
        p={5}
        py={"24px"}
        maxW={"400px"}
      >
        <Icon as={TbLock} boxSize={"30px"} />
        <Text fontSize={"14px"} textAlign={"center"}>
          We restricted your ability to access this team temporarily.
          <br /> You can restore access later by the team admin.
        </Text>
      </Flex>
    </Flex>
  );
}

export default AccessDisclaimer;
